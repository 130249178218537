export function parseCookieValue(value: string | undefined) {
  if (!value) return value;

  const decoded = decodeURIComponent(value);

  try {
    return JSON.parse(decoded);
  } catch {
    return decoded || value;
  }
}

export function getCookie<T = unknown>(name: string): T | null {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));

  if (match) {
    const value = match[2];

    return parseCookieValue(value) as T;
  }

  return null;
}
